import "./styles/App.css";

function App() {
    return (
        <div className="App">
            <header>
                <img className="logo" src="../img/logopng.png" alt="logo" />
            </header>

            <div className="text">
                <div className="textdescription">
                    <h1 className="description">Bienvenue sur mon site !</h1>

                    <h2>
                        En attendant qu'il soit complètement créé, vous pouvez
                        me retrouver (en cliquant sur les logos) :
                    </h2>

                    <div className="network">
                        <div className="bigcartel">
                            <a href="https://lillysbow.bigcartel.com/">
                                <img
                                    className="networklogo bigcartel"
                                    src="../img/bigcartel.com_logo.png"
                                    alt="bigcartel logo"
                                />
                            </a>
                            <p>
                                Sur Bigcartel pour des noeuds d'inspiration
                                Disney
                            </p>
                        </div>
                        <div className="etsy">
                            <a href="https://www.etsy.com/fr/shop/lillysbow">
                                <img
                                    className="networklogo bigcartel"
                                    src="../img/Etsy_logo.svg.png"
                                    alt="etsy logo"
                                />
                            </a>
                            <p>Sur Etsy pour des noeuds plus classiques</p>
                        </div>
                        <div className="instagram">
                            <a href="https://www.instagram.com/lillysbow/">
                                <img
                                    className="networklogo bigcartel"
                                    src="../img/Instagram_icon.png"
                                    alt="instagram logo"
                                />
                            </a>
                            <p>
                                Sur Instagram pour me parler ou pour passer une
                                commande personnalisée
                            </p>
                        </div>
                    </div>

                    <p>À bientôt !</p>
                    <p>- Lilly</p>
                </div>
            </div>
        </div>
    );
}

export default App;
